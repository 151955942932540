import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ArticleLift from "./ArticleLift"

import urlForContentType from "../../data/url-for-content-type.json"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../theme/constants"
import colors from "../theme/colors"
import typography from "../theme/typography"

const useStyles = makeStyles(theme => ({
  root: {
    background: colors.grey["-"],
    padding: "50px 0 60px",
  },
  headingLink: {
    textDecoration: "none",
    color: colors.base.black,
    "&:hover": {
      textDecoration: "underline",
      color: colors.base.black,
    },
  },
  heading: {
    marginBottom: "2rem",
  },
}))

const FeaturedPages = ({ title, linkTo, data, pageContext, location }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container>
        {title && linkTo ? (
          <Link to={linkTo} className={classes.headingLink}>
            <Typography className={classes.heading} variant="h3" component="h5">
              {title}
            </Typography>
          </Link>
        ) : (
          <Typography className={classes.heading} variant="h3" component="h5">
            {title}
          </Typography>
        )}

        {data && (
          <Grid container spacing={5}>
            {data.map(article => (
              <Grid item xs={12} md={4} key={article.id}>
                <ArticleLift
                  image={article.headingImage}
                  heading={article.heading ? article.heading : article.title}
                  text={R.path(["leadText", "leadText"], article)}
                  urlPath={
                    urlForContentType[
                      R.path(["sys", "contentType", "sys", "id"], article)
                    ]
                  }
                  slug={article.slug}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  )
}

export default FeaturedPages
