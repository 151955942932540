import React from "react"
import { Link } from "gatsby"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import GatsbyBackgroundImage from "gatsby-background-image"
import * as R from "ramda"

import urlForContentType from "../../data/url-for-content-type.json"
import safeSliceText from "../../utils/safeSliceText"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"

//import * as styles from "./ArticleLift.module.scss"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  heading: {
    "&.small": {
      display: "flex",
      alignItems: "flex-end",
      fontSize: "2.4rem",
      height: "4.8rem",
    },
  },
  text: {
    "&.small": {
      fontSize: "1rem",
    },
  },
  image: {
    width: "100%",
    height: "400px",
    [theme.breakpoints.up("md")]: {
      height: "250px",
    },
  },
  big: {
    width: "100%",
    height: "400px",
  },


  root: {
    color: "inherit",
    textDecoration: "none",
  
    "&.big": {
      display: "flex",
      margin: "2em 0 1em",
  
      image: {
        height: "400px"
      },
      "> div > div:not(.image)": {
        margin: "30px 0"
      },
  
      h2: {
        textTransform: "uppercase",
        margin: "0 0 24px"
      },
  
      button: {
        marginTop: "30px"
      }
    },
  
    image: {
      width: "100%",
      height: "400px"
    },
  
    h2: {
      marginBottom: "16px"
    }
  }  
}))

const ArticleLift = ({ size, align, image, heading, text, urlPath, slug }) => {
  const leadTextMaxLength = 255

  const convimage = getImage(image)
  const bgImage = convertToBgImage(convimage)

  const classes = useStyles()

  const imageBox = (
    <Grid
      item
      xs={12}
      md={size === "big" ? 6 : 12}
      className={size === "big" ? classes.big : classes.image}
    >
      <GatsbyBackgroundImage
        {...bgImage}
        style={{ width: "100%", height: "100%" }}
        alt=""
      />
    </Grid>
  )

  const infoBox = (
    <Grid item xs={12} md={size === "big" ? 6 : 12}>
      {heading && (
        <Typography
          className={`${classes.heading} ${size === "big" ? "big" : "small"}`}
          variant="h3"
          component="h2"
        >
          {heading}
        </Typography>
      )}
      {text && (
        <Typography
          className={`${classes.text} ${size === "big" ? "big" : "small"}`}
          variant="body1"
          component="p"
        >
          {safeSliceText(text, leadTextMaxLength)}
        </Typography>
      )}
      {size === "big" && (
        <Button
          variant="outlined"
          color="primary"
          disableRipple={true}
          disableFocusRipple={true}
        >
          Lue lisää
        </Button>
      )}
    </Grid>
  )

  return (
    <Link
      to={
        urlPath
          ? `/${urlPath}/${slug}`
          : `/${urlForContentType.article}/${slug}`
      }
      className={`${classes.root} ${size === "big" ? classes.big : null}`}
    >
      <Grid container spacing={size === "big" ? 4 : 2}>
        {/* small screens always image above text */}
        {align === "right" ? <Hidden mdUp>{imageBox}</Hidden> : imageBox}
        {infoBox}
        {align === "right" ? <Hidden smDown>{imageBox}</Hidden> : null}
      </Grid>
    </Link>
  )
}

export default ArticleLift
