export default (text, max) => {
  // As slicing is for lead text for articles, remove all newlines
  text = text.replace(/[\r\n]/g, "")
  // If no slicing is needed, return whole string
  if (text.length <= max) return text
  // Else slice to max length (only on whitespace)
  const re = new RegExp(`^.{1,${max}}[\\s$]`)
  const shortened = text.match(re)
  if (shortened) {
    return (
      shortened[0]
        // If string ends with full top & space, remove the space
        .replace(/\.\s$/, ".")
        // If string still ends with a space, add ellipsis (3 dots)
        .replace(/\s$/, "…")
    )
  } else {
    return text.slice(0, max).replace(/[\w\s]$/, "…")
  }
}
